import './UnigineSelect.scss';
import { JSX } from 'react';

import { MenuItem, Select, SelectProps } from '@mui/material';
import clsx from 'clsx';

interface IUnigineSelect<T> extends SelectProps<string> {
  name: string;
  value: string;
  options: T[];
  getLabel: (option: T) => string;
  getValue: (option: T) => string;
  className?: string;
  onChange?: (event: any) => void;
  labelId?: string;
}

const UnigineSelect = <T extends unknown>({
  name,
  value,
  onChange,
  options = [],
  getLabel,
  getValue,
  className,
  ...props
}: IUnigineSelect<T>): JSX.Element => {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      className={clsx('unigine-select', className)}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: '#2a323b',
            color: '#eff3f7',
          },
        },
      }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={getValue(option)} value={getValue(option)}>
          {getLabel(option)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UnigineSelect;

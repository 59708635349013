import './SettingsSection.scss';

import React, { FC, useState } from 'react';
import { Button, Card, CardActionArea, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';

import DiscordIcon from 'app/assets/discord.svg';
import InstagramIcon from 'app/assets/instagram.svg';
import FacebookIcon from 'app/assets/facebook.svg';
import LinkedInIcon from 'app/assets/linkedin.svg';
import TwitterIcon from 'app/assets/x.svg';
import YoutubeIcon from 'app/assets/youtube.svg';
import EditIcon from 'app/assets/edit.svg';
import DefaultAvatarIcon from 'app/assets/avatar-default.svg';

import AddonMediaDialog from 'app/components/AddonDialogs/AddonMediaDialog';

import {
  currentPublisherInfoSelector,
  openMediaDialog,
  closeMediaDialog,
  removePictureFile,
  removeVideoFile,
  AddonMediaDialogSelector,
  getVideoInfoById,
  addVideoFile,
  addPictureFile,
  addAvatarFile,
} from 'app/main/sections/PublisherPanel/sections/SettingsSection/store/settingsSlice';

const SettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const currentPublisher = useAppSelector(currentPublisherInfoSelector);
  const mediaInfo = useAppSelector(AddonMediaDialogSelector);

  const handleDeleteImageClick = (e: React.MouseEvent<HTMLButtonElement>, id: string): void => {
    e.stopPropagation();
    dispatch(removePictureFile(id));
  };

  const handleDeleteVideoClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    videoId: string
  ): void => {
    e.stopPropagation();
    dispatch(removeVideoFile(videoId));
  };

  const handleMediaCardClick = (media: any): void => {
    dispatch(openMediaDialog({ media, isFormImage: false, isFormVideo: false }));
  };

  const handleAddImageButton = (): void => {
    dispatch(openMediaDialog({ media: null, isFormImage: true, isFormVideo: false }));
  };

  const handleAddVideoButton = (): void => {
    dispatch(openMediaDialog({ media: null, isFormImage: false, isFormVideo: true }));
  };

  const onChangeAvatar = (e: React.FormEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    if (target?.files) {
      const avatarFile = target?.files[0];
      dispatch(addAvatarFile(avatarFile));
    }
  };

  return (
    <div className="publisher-panel__wrapper--no-header">
      <div className="settings__title-container">
        <h1 className="settings__title">Settings</h1>

        <div className="settings__buttons-container to-right">
          <Link to="/publisherPanel/settings/edit" className="settings__edit-button">
            <img src={EditIcon} width="20" alt="edit" />
            Edit
          </Link>
          <Link
            to={`/publisher/${currentPublisher?.id}`}
            target="_blank"
            className="settings__view-profile-button"
          >
            View Profile
          </Link>
        </div>
      </div>

      {currentPublisher && (
        // TODO use DataList
        <section className="settings__info-block">
          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Name</div>
            <div className="settings__info-info">{currentPublisher?.name}</div>
          </div>

          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Contact Email</div>
            <div className="settings__info-info">{currentPublisher?.contacts?.email || '-'}</div>
          </div>

          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Site URL</div>
            <div className="settings__info-info">
              {currentPublisher?.contacts?.site_link || '-'}
            </div>
          </div>

          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Social media links</div>
            <div className="settings__contacts-wrapper">
              {currentPublisher?.contacts.discord && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={DiscordIcon}
                    width="26"
                    alt="discord"
                  />
                  <div>{currentPublisher?.contacts.discord}</div>
                </div>
              )}
              {currentPublisher?.contacts.instagram && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={InstagramIcon}
                    width="26"
                    alt="instagram"
                  />
                  <div>{currentPublisher?.contacts.instagram}</div>
                </div>
              )}
              {currentPublisher?.contacts.twitter && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={TwitterIcon}
                    width="26"
                    alt="twitter"
                  />
                  {currentPublisher?.contacts.twitter}
                </div>
              )}
              {currentPublisher?.contacts.youtube && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={YoutubeIcon}
                    width="26"
                    alt="youtube"
                  />
                  {currentPublisher?.contacts.youtube}
                </div>
              )}
              {currentPublisher?.contacts.facebook && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={FacebookIcon}
                    width="26"
                    alt="facebook"
                  />
                  {currentPublisher?.contacts.facebook}
                </div>
              )}
              {currentPublisher?.contacts.linkedin && (
                <div className="settings__contacts">
                  <img
                    className="settings__contacts-icon"
                    src={LinkedInIcon}
                    width="26"
                    alt="linkedin"
                  />
                  {currentPublisher?.contacts.linkedin}
                </div>
              )}
            </div>
          </div>

          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Description</div>
            <div>
              <div
                className={clsx(
                  'settings__info-info',
                  'settings__info-description',
                  'text-format',
                  {
                    'settings__info-description--open': descriptionOpen,
                  }
                )}
                dangerouslySetInnerHTML={{ __html: currentPublisher?.description }}
              />
              {!descriptionOpen && (
                <Button
                  className="settings__button-expand"
                  onClick={() => setDescriptionOpen(true)}
                >
                  Read more
                </Button>
              )}
            </div>
          </div>

          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Avatar</div>
            <div className="settings__avatar-wrapper">
              {currentPublisher.icon80File ? (
                <img
                  src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${currentPublisher.icon80File.id}`}
                  alt={currentPublisher.name}
                  width="140"
                />
              ) : (
                <img width="140" src={DefaultAvatarIcon} alt={currentPublisher.name} />
              )}
              <label htmlFor="change-avatar" className="settings__change-avatar-button">
                change avatar
                <input
                  className="dialog-media__input--file"
                  type="file"
                  id="change-avatar"
                  accept="image/*"
                  onChange={onChangeAvatar}
                />
              </label>
            </div>
          </div>
          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Pictures</div>
            <div className="media__container settings__info-info">
              <ul className="media__list">
                <li className="media__item">
                  <Button
                    className="media__button"
                    onClick={() => handleAddImageButton()}
                    type="button"
                  />
                </li>

                {currentPublisher.pictureFiles &&
                  currentPublisher.pictureFiles.map((image) => (
                    <li key={image.id} className="media__item">
                      <Card className="media__card">
                        <div className="media__action-container">
                          <CardActionArea
                            className="media__image-container"
                            onClick={() => handleMediaCardClick(image)}
                          >
                            <img
                              className="media__image"
                              src={`${process.env.REACT_APP_STORE_API_URL}/media-files/${image.id}`}
                              alt={image.url}
                            />
                          </CardActionArea>

                          <IconButton
                            aria-label="close"
                            title="Delete media file"
                            className="media__image-button"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                              handleDeleteImageClick(e, image.id)
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>

                        <h3 className="media__title">{image.id}</h3>
                      </Card>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="settings__info-row form-grid__group-label">
            <div className="settings__info-title">Videos</div>
            <div className="media__container settings__info-info">
              <ul className="media__list">
                <li className="media__item">
                  <Button
                    className="media__button"
                    onClick={() => handleAddVideoButton()}
                    type="button"
                  />
                </li>

                {currentPublisher.videoFiles &&
                  currentPublisher.videoFiles.map((video) => (
                    <li key={video.id} className="media__item">
                      <Card className="media__card">
                        <div className="media__action-container">
                          <CardActionArea
                            className="media__image-container"
                            onClick={() => handleMediaCardClick(video.data)}
                          >
                            <img
                              className="media__image"
                              src={`https://img.youtube.com/vi/${video.data.videoId}/hqdefault.jpg`}
                              alt={video.data.videoId}
                            />
                          </CardActionArea>

                          <IconButton
                            aria-label="close"
                            title="Delete media file"
                            className="media__image-button"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                              handleDeleteVideoClick(e, video.id)
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>

                        <h3 className="media__title">{video.data.title}</h3>
                      </Card>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <AddonMediaDialog
            type="publisher"
            closeMediaDialog={closeMediaDialog}
            getVideoInfoById={getVideoInfoById}
            addVideoFile={addVideoFile}
            addPictureFile={addPictureFile}
            mediaInfo={mediaInfo}
          />
        </section>
      )}
    </div>
  );
};

export default SettingsSection;

import './PackageList.scss';

import { FC, useEffect, useRef, useState } from 'react';
import { filesize } from 'filesize';

import { ReactComponent as DownloadIcon } from 'app/assets/icon_download_package.svg';

import IPackage from 'app/interfaces/addons/IPackage';
import DownloadPackage from 'app/components/DownloadPackage/DownloadPackage';
import PlatformIcon from 'app/components/PlatformIcon/PlatformIcon';
import clsx from 'clsx';

interface PackageItemProps {
  package: IPackage;
  canDownload?: boolean;
}

interface PackageListProps {
  packages: IPackage[];
  canDownload?: boolean;
}

interface PackageGroupedListProps {
  groups: {
    label: string;
    packages: IPackage[];
  }[];
  canDownload?: boolean;
}

const PackageItem: FC<PackageItemProps> = ({ package: pkg, canDownload = false }) => {
  const { description, platform, packageSize, sdkVersions, sourceCode } = pkg;

  // the element renders in collapsed state by default,
  // then effect fires & decides whether the button needs to be shown
  const expandableRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);
  useEffect(() => {
    if (expandableRef.current) {
      const { scrollHeight, clientHeight } = expandableRef.current;
      setShowExpandBtn(scrollHeight > clientHeight);
    }
  }, []);

  return (
    <article className="package">
      <dl className="package__attributes">
        <dt>Platform</dt>
        <dd className="package__platform">
          <span className={`package__platform-icon package__platform-icon--${platform.value}`}>
            <PlatformIcon platform={platform} />
          </span>
          {platform?.value}
        </dd>

        <dt>File size</dt>
        <dd>{packageSize && filesize(packageSize).toString()}</dd>

        <dt>SDK version{sdkVersions?.length > 1 ? 's' : ''}</dt>
        <dd>{sdkVersions?.map((version) => version.value).join(', ')}</dd>

        <dt>Source code</dt>
        <dd>{sourceCode?.value}</dd>
      </dl>
      <div className="package__description">
        {description && description !== '<p></p>' ? (
          <>
            <div
              ref={expandableRef}
              className={clsx('package__description-content', 'text-format', {
                'package__description-content--expanded': isExpanded,
              })}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {showExpandBtn && !isExpanded && (
              <button
                type="button"
                onClick={() => setIsExpanded(true)}
                className="package__expand-btn"
              >
                Read more
              </button>
            )}
          </>
        ) : (
          <span className="package__empty-description">No description</span>
        )}
      </div>
      <div className="package__actions">
        {canDownload && (
          <DownloadPackage packageId={pkg.id} className="package__download-btn" source="storefront">
            <DownloadIcon />
            Download
          </DownloadPackage>
        )}
      </div>
    </article>
  );
};

const PackageList: FC<PackageListProps> = ({ packages, canDownload = false }) => {
  return (
    <ul className="package-list">
      {packages.map((pkg) => (
        <li key={pkg.id} className="package-list__item">
          <PackageItem package={pkg} canDownload={canDownload} />
        </li>
      ))}
    </ul>
  );
};

const PackageGroupedList: FC<PackageGroupedListProps> = ({ groups, canDownload = false }) => {
  return (
    <ul className="package-groups">
      {groups.map((group) => (
        <li className="package-groups__group" key={group.label}>
          <span className="package-groups__group-label">{group.label}</span>
          <PackageList packages={group.packages} canDownload={canDownload} />
        </li>
      ))}
    </ul>
  );
};

export default PackageList;
export { PackageGroupedList };

import './AddonPackageDialog.scss';

import { FC, MouseEvent, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';

import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import CheckboxGroup from 'app/components/CheckboxGroup/CheckboxGroup';
import RadioGroup from 'app/components/RadioGroup/RadioGroup';
import RichTextEditor from 'app/components/RichTextEditor/RichTextEditor';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import useAppSelector from 'app/hooks/useAppSelector';
import { platformNames, regExps } from 'app/configs/appConfig';
import { formFieldErrors, tooltips } from 'app/utils/constants/contentConstants';

import { selectVersions } from 'app/main/sections/Addons/store/versionsSlice';
import { selectPlatform } from 'app/main/sections/Addons/store/platformSlice';
import { selectSourceLanguages } from 'app/main/sections/Addons/store/sourceLanguagesSlice';
import { vaildatePackageFile } from 'app/main/sections/PublisherPanel/sections/AddonSection/tabs/AddonPackageHelpers';
import { IPackageData } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';

export interface IPackageWithFiles extends IPackageData {
  files: FileList;
}

interface IAddonAddPackageDialog {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (data: IPackageWithFiles) => Promise<void>;
}

const AddonAddPackageDialog: FC<IAddonAddPackageDialog> = ({
  onClose,
  isOpen,
  onSubmit,
  isSubmitting,
}) => {
  const versionsData = useAppSelector(selectVersions);
  const platformData = useAppSelector(selectPlatform);
  const platformOptions = useMemo(
    () => platformData?.map((platform) => ({ ...platform, label: platformNames[platform.value] })),
    [platformData]
  );
  const sourceLanguagesData = useAppSelector(selectSourceLanguages);

  interface IDefaultValues {
    files: FileList;
    description?: string;
    platformId: string;
    sourceCodeId: string;
    sdkVersions: string[];
  }

  const defaultValues: IDefaultValues = {
    sdkVersions: [],
    platformId: platformOptions ? platformOptions[0].id : '', // fix later...
    sourceCodeId: sourceLanguagesData ? sourceLanguagesData[0].id : '',
    description: '',
    files: new DataTransfer().files,
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const onPackageClick = (e: MouseEvent<HTMLInputElement>): void => {
    e.currentTarget.value = '';
  };

  if (!sourceLanguagesData || !platformOptions) {
    return (
      <Dialog open={isOpen} fullWidth maxWidth="md" onClose={onClose} className="dialog">
        <DialogContent className="dialog-package__content">
          <CircularProgress color="inherit" />

          <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={onClose} className="dialog" disableEnforceFocus>
      <DialogContent className="dialog-package__content">
        <h2 className="dialog-package__title">Upload Package</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <FormRow required label="Package file">
              <Controller
                control={control}
                name="files"
                rules={{
                  validate: (value) => {
                    const packageFile = value?.[0];

                    const error = vaildatePackageFile(packageFile);

                    return error || true;
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const name = value ? value[0]?.name : '';
                  return (
                    <div className="add-package-file-container">
                      <TextField
                        size="small"
                        className="unigine-form-field"
                        variant="outlined"
                        fullWidth
                        disabled
                        value={name}
                      />

                      <div className="dialog-package-file-wrapper">
                        <label
                          htmlFor="dialog-package-file"
                          className={clsx('dialog-package__label', {
                            // 'add-package__label--disabled': isProcessing,
                          })}
                        >
                          Select Upackage
                          <input
                            className="add-package__file"
                            type="file"
                            id="dialog-package-file"
                            accept=".upackage"
                            onChange={(v) => onChange(v.target.files)}
                            onClick={onPackageClick}
                            // disabled={isProcessing}
                          />
                        </label>
                        {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                      </div>
                    </div>
                  );
                }}
              />
            </FormRow>
            <FormRow required label="Supported versions">
              <Controller
                control={control}
                name="sdkVersions"
                rules={{
                  validate: (value) => value.length > 0 || 'Please select at least one value', // these black magic APIs I swear
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <CheckboxGroup
                      items={versionsData || []}
                      selectedValues={value}
                      onChange={onChange}
                      getLabel={(item) => item.value}
                      getValue={(item) => item.id}
                      error={error}
                    />
                  );
                }}
              />
            </FormRow>

            <FormRow required label="Platform">
              <Controller
                control={control}
                name="platformId"
                defaultValue={platformOptions[0].id}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <RadioGroup
                      options={platformOptions}
                      selectedValue={value}
                      getValue={(item) => item.id}
                      onChange={onChange}
                      error={error}
                    />
                  );
                }}
              />
            </FormRow>

            {sourceLanguagesData && (
              <FormRow required label="Source code">
                <Controller
                  control={control}
                  name="sourceCodeId"
                  defaultValue={sourceLanguagesData[0].id}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="add-on-form__select"
                      value={value}
                      onChange={onChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#2a323b',
                            color: '#eff3f7',
                          },
                        },
                      }}
                    >
                      {sourceLanguagesData.map((lang) => (
                        <MenuItem value={lang.id} key={lang.id}>
                          {lang.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormRow>
            )}

            <FormRow label="Description" tooltip={tooltips.addonForm.sysreq}>
              <Controller
                control={control}
                name="description"
                rules={{
                  maxLength: {
                    value: 1000,
                    message: formFieldErrors.richTextLength,
                  },
                  pattern: {
                    value: regExps.richText,
                    message: formFieldErrors.richTextCharacters,
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <RichTextEditor initialValue={value} onChange={onChange} error={error} />

                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </>
                )}
              />
            </FormRow>
          </FormGrid>
          <div className="dialog-package__button-container">
            <UnigineButton
              type="submit"
              className="dialog-package__button dialog-package__button--submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress color="inherit" size={16} /> : 'Upload Package'}
            </UnigineButton>
          </div>
        </form>
        <IconButton aria-label="close" className="dialog__close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddonAddPackageDialog;

import './AddonChangelogEditTab.scss';

import { FC, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@mui/material';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

import { FormGrid } from 'app/components/FormGrid/FormGrid';
import RichTextEditor, { clearSave } from 'app/components/RichTextEditor/RichTextEditor';

import { formFieldErrors } from 'app/utils/constants/contentConstants';
import { IPublishedAddonVersionBaseInfo } from 'app/interfaces/addons/IAddonVersion';
import { IPublishedAddon } from 'app/interfaces/addons/IAddon';

import {
  addonBaseInfoSelector,
  editAddonBaseInfo,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import { addonSelector } from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';

const AddonChangelogEditTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addonId } = useParams();
  const [searchParams] = useSearchParams();

  const addonBaseInfo = useAppSelector(addonBaseInfoSelector) as IPublishedAddonVersionBaseInfo;
  const addonInfo = useAppSelector(addonSelector) as IPublishedAddon;

  const { changeLog, id, status } = addonBaseInfo;
  const { isBanned } = addonInfo;

  useEffect(() => {
    if (status.value !== 'Draft' || isBanned) {
      navigate(`/publisherPanel/add-ons/${addonId}/changelog?${searchParams.toString()}`);
    }
  }, [addonId, isBanned, navigate, searchParams, status]);

  const defaultValues = {
    changeLog: changeLog || '',
  };

  const { control, handleSubmit, setError } = useForm({ defaultValues });

  const onFormSubmit = async (addonData: { changeLog: string }): Promise<void> => {
    try {
      await dispatch(editAddonBaseInfo({ addonData, addonVersionId: id })).unwrap();
      clearSave(`${addonId}_changelog`);
      navigate(`/publisherPanel/add-ons/${addonId}/changelog`);
    } catch (err: any) {
      const { context } = err;
      if (Array.isArray(context)) {
        context.forEach((error) => setError(error.name, { message: error.message }));
      } else {
        console.error('Could not update add-on:', err.message, '\n', err);
      }
    }
  };

  return (
    <section className="add-on-section">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="add-on-changelog-edit__form add-on-form"
      >
        <FormGrid>
          <Controller
            control={control}
            name="changeLog"
            rules={{
              validate: (value) =>
                (value.length > 0 && value !== '<p></p>') || formFieldErrors.required,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RichTextEditor
                autosaveKey={`${addonId}_changelog`}
                initialValue={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </FormGrid>

        <div className="add-on-section__actions">
          <Button
            type="submit"
            className="add-on-section__action-button add-on-section__action-button--edit-content"
          >
            Save
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddonChangelogEditTab;

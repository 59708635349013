import './AddonChangelogTab.scss';

import { FC } from 'react';

import { addonBaseInfoSelector } from 'app/main/sections/AdminPortal/sections/AddonSection/store/baseInfoSlice';
import useAppSelector from 'app/hooks/useAppSelector';

const AddonChangelogTab: FC = () => {
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);

  if (!addonBaseInfo) {
    return <>Loading...</>;
  }

  const { changeLog } = addonBaseInfo;

  return (
    <section className="add-on-section">
      <div className="add-on-section__content">
        <div
          className="add-on-section__changelog text-format"
          dangerouslySetInnerHTML={{ __html: changeLog }}
        />
      </div>

      <div className="add-on-section__actions" />
    </section>
  );
};

export default AddonChangelogTab;

import './AddonBaseInfoEditTab.scss';

import { useState, useEffect, useMemo, useId, FC, MouseEvent } from 'react';

import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { Button, CircularProgress, FormHelperText, TextField } from '@mui/material';

import CheckboxGroup from 'app/components/CheckboxGroup/CheckboxGroup';
import { FormGrid, FormRow } from 'app/components/FormGrid/FormGrid';
import RichTextEditor, { clearSave } from 'app/components/RichTextEditor/RichTextEditor';
import TagsAutocomplete from 'app/components/TagsAutocomplete/TagsAutocomplete';
import UnigineTooltip from 'app/components/Tooltip/Tooltip';
import { useConfirmNavigateDialog } from 'app/components/ConfirmNavigateDialog/ConfirmNavigateDialog';

import { selectProducts } from 'app/main/sections/Addons/store/productsSlice';

import { categoriesSelector } from 'app/store/unigine/categoriesSlice';
import {
  confirmNavigateTitle,
  formFieldErrors,
  tooltips,
} from 'app/utils/constants/contentConstants';
import getConcreteCategories from 'app/utils/helpers/getConcreteCategories';
import { productSlugsSortedByTier, regExps } from 'app/configs/appConfig';

import {
  addonBaseInfoSelector,
  editAddonBaseInfo,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/baseInfoSlice';
import {
  addonSelector,
  getAddonInfo,
} from 'app/main/sections/PublisherPanel/sections/AddonSection/store/addonInfoSlice';

import { IEditAddonFieldValues } from 'app/interfaces/addons/IEditAddon';
import { IPublishedAddonVersionBaseInfo } from 'app/interfaces/addons/IAddonVersion';
import { IPublishedAddonGeneralInfo } from 'app/interfaces/addons/IAddon';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useAppSelector from 'app/hooks/useAppSelector';

const AddonBaseInfoEditTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addonId } = useParams();
  const addonBaseInfo = useAppSelector(addonBaseInfoSelector);
  const addonInfo = useAppSelector(addonSelector);
  const [searchParams] = useSearchParams();
  const formId = useId();
  const { ConfirmNavigateDialog, confirmAction, CANCEL_ACTION, CONFIRM_ACTION } =
    useConfirmNavigateDialog(confirmNavigateTitle);

  const {
    id,
    versionNumber,
    categories,
    products,
    description,
    tags,
    systemRequirements,
    status,
    parent: parentVersion,
  } = addonBaseInfo as IPublishedAddonVersionBaseInfo;
  const { isBanned } = addonInfo as IPublishedAddonGeneralInfo;

  useEffect(() => {
    if (status.value !== 'Draft' || isBanned) {
      navigate(`/publisherPanel/add-ons/${addonId}/base?${searchParams.toString()}`);
    }
  }, [addonId, isBanned, navigate, searchParams, status]);

  const categoriesData = useAppSelector(categoriesSelector);
  const concreteCategories = useMemo(() => getConcreteCategories(categoriesData), [categoriesData]);

  const productsData = useAppSelector(selectProducts);

  const defaultValues = {
    versionNumber: versionNumber || '',
    categories: categories.map((item) => item.id) || [],
    products: products.map((item) => item.id) || [],
    tags: tags || [],
    description: description || '',
    systemRequirements: systemRequirements || '',
  };

  const { control, handleSubmit, setError, watch } = useForm<IEditAddonFieldValues>({
    mode: 'onTouched',
    defaultValues,
  });

  const [isWaiting, setIsWaiting] = useState(false);
  const onFormSubmit = async (addonData: IEditAddonFieldValues): Promise<void> => {
    const { tags: _, ...addonDataForApi } = { ...addonData };
    const tagsData = addonData.tags.map((item) => item.value);

    if (!addonId) {
      return;
    }

    try {
      setIsWaiting(true);
      await dispatch(
        editAddonBaseInfo({ addonData: { ...addonDataForApi, tags: tagsData }, addonVersionId: id })
      ).unwrap();
      await dispatch(getAddonInfo({ addonId }));
      clearSave(`${formId}_description`);
      clearSave(`${formId}_sys_req`);

      navigate(`/publisherPanel/add-ons/${addonId}/base?${searchParams.toString()}`);
    } catch (err: any) {
      const {
        data: { context },
      } = err;
      if (Array.isArray(context)) {
        context.forEach((error) => setError(error.name, { message: error.message }));
      } else {
        console.error('Could not update add-on:', err.message, '\n', err);
      }
    } finally {
      setIsWaiting(false);
    }
  };

  const selectedProducts = watch('products');
  const productsSortedByTier = useMemo(
    () => productSlugsSortedByTier.map((slug) => productsData?.find((prod) => prod.slug === slug)),
    [productsData]
  );

  // intercept the changes to the values that are constrained by the parent version
  const handleProductChange = (newSelectedProductIds: string[]): string[] => {
    const parentProductIds = parentVersion?.products.map((prod) => prod.id) || [];

    const narrowestProductIndex = productsSortedByTier.findIndex((prod) =>
      newSelectedProductIds.find((prodId) => prod?.id === prodId)
    );

    let addedProductIds: string[] = [];
    if (narrowestProductIndex >= 0) {
      addedProductIds = productsSortedByTier
        .slice(narrowestProductIndex, productsSortedByTier.length)
        .flatMap((prod) => (prod?.id ? [prod.id] : []));
    }

    return [
      ...parentProductIds,
      ...addedProductIds.filter((prod) => !parentProductIds.includes(prod)),
    ];
  };

  const handleCategoriesChange = (newCategories: string[]): string[] => {
    const parentCategoryIds = parentVersion?.categories.map((cat) => cat.id) || [];
    return [
      ...parentCategoryIds,
      ...newCategories.filter((cat) => !parentCategoryIds.includes(cat)),
    ];
  };

  const handleTagsChange = (newTags: { value: string }[]): { value: string }[] => {
    const parentTags = parentVersion?.tags || [];

    return [
      ...parentTags,
      ...newTags.filter((tag) => !parentTags.find((parentTag) => parentTag.value === tag.value)),
    ];
  };

  // disable the controls for options that must not be changed from the parent version
  const disabledProducts = useMemo(() => {
    const narrowestProductIndex = productsSortedByTier.findIndex((prod) =>
      selectedProducts.find((prodId) => prod?.id === prodId)
    );

    let autoDisabledProductIds: string[] = [];
    if (narrowestProductIndex >= 0) {
      autoDisabledProductIds = productsSortedByTier
        .slice(narrowestProductIndex + 1, productsSortedByTier.length)
        .flatMap((prod) => (prod?.id ? [prod.id] : []));
    }

    const parentVersionProductIds = parentVersion?.products.map((prod) => prod.id) || [];
    return [
      ...parentVersionProductIds,
      ...autoDisabledProductIds.filter((prod) => !parentVersionProductIds.includes(prod)),
    ];
  }, [parentVersion, productsSortedByTier, selectedProducts]);

  const disabledCategories = parentVersion?.categories.map((cat) => cat.id) || [];

  const handleScroll = (elementId: string): void => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleCancelClick = async (e: MouseEvent<HTMLAnchorElement>): Promise<void> => {
    e.preventDefault();

    const choice = await confirmAction();

    if (choice === CANCEL_ACTION) {
      return;
    }

    if (choice === CONFIRM_ACTION) {
      clearSave(`${formId}_description`);
      clearSave(`${formId}_sys_req`);
      navigate(`/publisherPanel/add-ons/${addonId}/base?${searchParams.toString()}`);
    }
  };

  return (
    <section className="add-on-section">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="add-on-base-info-edit__form add-on-form"
      >
        <FormGrid>
          <FormRow
            required
            label="Version number"
            labelledInputId={`${formId}_edit_input_version_number`}
            tooltip={tooltips.addonVersionForm.versionNumber}
          >
            <Controller
              control={control}
              name="versionNumber"
              rules={{
                required: formFieldErrors.required,
                maxLength: {
                  value: 20,
                  message: 'Version number must be at most 20 characters long',
                },
                pattern: {
                  value: /^[a-zA-Z0-9.,]+$/,
                  message: 'Title can only contain Latin characters, numbers, commas, and dots',
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <UnigineTooltip title={tooltips.addonVersionForm.versionNumber}>
                    <TextField
                      {...field}
                      size="small"
                      className="unigine-form-field"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{
                        id: `${formId}_edit_input_version_number`,
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </UnigineTooltip>
                );
              }}
            />
          </FormRow>

          <FormRow required label="Categories" tooltip={tooltips.addonForm.categories}>
            <Controller
              control={control}
              name="categories"
              rules={{
                validate: (value) => value.length > 0 || 'Please select at least one value',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value.length === 0) {
                  handleScroll(`${formId}_categories`);
                }
                return (
                  <CheckboxGroup
                    id={`${formId}_categories`}
                    items={concreteCategories}
                    selectedValues={value}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                    getIsDisabled={(item) => disabledCategories.includes(item.id)}
                    onChange={(newCategories) => onChange(handleCategoriesChange(newCategories))}
                    error={error}
                  />
                );
              }}
            />
          </FormRow>

          <FormRow required label="Required Licences" tooltip={tooltips.addonForm.products}>
            <Controller
              control={control}
              name="products"
              rules={{
                validate: (value) => value.length > 0 || 'Please select at least one value',
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value.length === 0) {
                  handleScroll(`${formId}_products`);
                }
                return (
                  <CheckboxGroup
                    id={`${formId}_products`}
                    items={productsData || []}
                    selectedValues={value}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                    getIsDisabled={(item) => disabledProducts.includes(item.id)}
                    onChange={(newProducts) => onChange(handleProductChange(newProducts))}
                    error={error}
                  />
                );
              }}
            />
          </FormRow>

          <FormRow label="Tags" tooltip={tooltips.addonForm.tags}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <UnigineTooltip title={tooltips.addonForm.tags}>
                    <TagsAutocomplete
                      value={value}
                      onChange={(newTags) => onChange(handleTagsChange(newTags))}
                      variant="outlined"
                      error={!!error}
                      validation={{
                        pattern: regExps.tags,
                        message: formFieldErrors.tagsCharacters,
                      }}
                    />
                  </UnigineTooltip>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
            />
          </FormRow>

          <FormRow label="Description" required tooltip={tooltips.addonForm.description}>
            <Controller
              control={control}
              name="description"
              rules={{
                maxLength: {
                  value: 1000,
                  message: formFieldErrors.richTextLength,
                },
                pattern: {
                  value: regExps.richText,
                  message: formFieldErrors.richTextCharacters,
                },
                validate: (value) =>
                  (value.length > 0 && value !== '<p></p>') || formFieldErrors.required,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (error && value === '<p></p>') {
                  handleScroll(`${formId}_description`);
                }
                return (
                  <RichTextEditor
                    autosaveKey={`${formId}_description`}
                    initialValue={value}
                    onChange={onChange}
                    error={error}
                    tooltip={tooltips.addonForm.description}
                  />
                );
              }}
            />
          </FormRow>

          <FormRow label="System requirements" tooltip={tooltips.addonForm.sysreq}>
            <Controller
              control={control}
              name="systemRequirements"
              rules={{
                maxLength: {
                  value: 1000,
                  message: formFieldErrors.richTextLength,
                },
                pattern: {
                  value: regExps.richText,
                  message: formFieldErrors.richTextCharacters,
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <RichTextEditor
                  initialValue={value}
                  autosaveKey={`${formId}_sys_req`}
                  tooltip={tooltips.addonForm.sysreq}
                  onChange={onChange}
                  error={error}
                />
              )}
            />
          </FormRow>
        </FormGrid>

        <div className="add-on-section__actions">
          <Button
            type="submit"
            className="add-on-section__action-button add-on-section__action-button--edit-content"
            disabled={isWaiting}
          >
            {isWaiting ? <CircularProgress color="inherit" size={16} /> : 'Save Base Info'}
          </Button>

          <Button
            className="add-on-section__action-button add-on-section__action-button--cancel-edit"
            component={Link}
            to={`/publisherPanel/add-ons/${addonId}/base?${searchParams.toString()}`}
            onClick={handleCancelClick}
            disabled={isWaiting}
          >
            Cancel
          </Button>
        </div>
      </form>

      <ConfirmNavigateDialog />
    </section>
  );
};

export default AddonBaseInfoEditTab;

import './AddonDialog.scss';

import { FC, useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import { CircularProgress, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoginGuard from 'app/hooks/useLoginGuard';
import useInCart from 'app/hooks/useInCart';
import useAppSelector from 'app/hooks/useAppSelector';

import Stars from 'app/components/Stars/Stars';
import PublisherLink from 'app/components/PublisherLink/PublisherLink';
import CategoryTags from 'app/components/CategoryTags/CategoryTags';
import PriceDisplay from 'app/components/PriceDisplay/PriceDisplay';
import DownloadPackage from 'app/components/DownloadPackage/DownloadPackage';
import PurchaseButton from 'app/components/PurchaseButton/PurchaseButton';
import MediaViewer from 'app/main/sections/Addon/AddonMediaViewer';
import UnigineButton from 'app/components/UnigineButton/UnigineButton';

import PackageListDialog from 'app/components/PackageListDialog/PackageListDialog';

import combineMedia from 'app/utils/helpers/combineMedia';

import { addToCart } from 'app/store/unigine/cartSlice';
import {
  addonDialogSelector,
  closeAddonDialog,
  getAddonDialogBaseInfo,
  getFreeAddon,
  getAddonVersionData,
  addonVersionSelector,
} from 'app/main/sections/Addon/store/baseInfoSlice';

import IPackage from 'app/interfaces/addons/IPackage';

const AddonDialog: FC<{ hidePurchaseButton?: boolean }> = ({ hidePurchaseButton }) => {
  const dispatch = useAppDispatch();

  const dispatchOrLogin = useLoginGuard();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [packageListOpen, setPackageListOpen] = useState(false);
  const { openAddonId, info, error } = useAppSelector(addonDialogSelector);
  const addonVersion = useAppSelector(addonVersionSelector);

  const isOpen = !!openAddonId;
  const isAddonValid = info?.id === openAddonId;

  const isInCart = useInCart(openAddonId);

  useEffect(() => {
    if (!isAddonValid && openAddonId) {
      setIsLoading(true);

      dispatch(getAddonDialogBaseInfo({ addonId: openAddonId })).then(() => {
        setIsLoading(false);
      });
    } else if (isAddonValid) {
      setIsLoading(false);
    }
  }, [dispatch, openAddonId, isAddonValid]);

  // the version marked as current in addon data
  const currentVersion = useMemo(() => {
    const versions = info?.publishedAndRevisionAddonVersions;
    const version = versions?.find((v) => v.isCurrent) || versions?.[0] || null;
    return version;
  }, [info]);

  const selectedVersionId = currentVersion?.id || null;

  useEffect(() => {
    if (selectedVersionId) {
      setIsLoading(true);
      dispatch(getAddonVersionData({ addonVersionId: selectedVersionId })).finally(() =>
        setIsLoading(false)
      );
    } else {
      setIsLoading(false);
    }
  }, [dispatch, selectedVersionId]);

  // set closed state on unmount
  useEffect(
    () => () => {
      dispatch(closeAddonDialog());
    },
    [dispatch, location]
  );

  const handleClose: () => void = () => {
    dispatch(closeAddonDialog());
  };

  if (isLoading) {
    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        className="dialog"
      >
        <DialogContent className="dialog__content dialog__content--loading">
          <CircularProgress color="inherit" className="dialog__loading" />

          <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  }

  if (error || !info || !addonVersion) {
    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        className="dialog"
      >
        <DialogContent className="dialog__content dialog__content--loading">
          <span className="dialog__no-add-on">{error?.data.message || 'Something went wrong'}</span>
          <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  }

  const {
    id: addonId,
    title,
    publisher,

    price,
    discountedPrice,
    isFree,
    ownedStatus,

    sdkVersions,
    publishedAndRevisionAddonVersions: publishedVersions,

    ratingCount,
    totalRating,
  } = info;

  const { id: currentVersionId, categories, releaseDate, description } = addonVersion;

  const media = combineMedia({
    pictureFiles: addonVersion?.pictureFiles || [],
    videoFiles: addonVersion?.videoFiles || [],
  });

  let singlePackage: IPackage | null = null;
  if (publishedVersions.length === 1 && publishedVersions[0].packages.length === 1) {
    [singlePackage] = publishedVersions[0].packages;
  }

  const isPurchased = ownedStatus === 'purchased';
  const isObtained = ownedStatus === 'obtained';
  const isUnavailable = ownedStatus === 'not available';

  const handleBuy = async (): Promise<void> => {
    setIsProcessing(true);
    try {
      if (isFree) {
        await dispatchOrLogin(getFreeAddon({ addonId, versionId: currentVersionId }));
        return;
      }

      await dispatchOrLogin(addToCart({ versionId: currentVersionId }));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        className="dialog"
      >
        <DialogContent className="dialog__content">
          <section className="add-on__header-info">
            <div className="dialog__publisher-link">
              <PublisherLink publisher={publisher} />
            </div>
            <h1 className="add-on-base-info__title">{title}</h1>

            <div className="dialog__rating">
              <Stars ratingCount={ratingCount} totalRating={parseFloat(totalRating)} />
            </div>

            <div className="dialog__category">
              <CategoryTags categories={categories} />
            </div>
          </section>
          <div className="add-on__main-content add-on__main-content--dialog add-on__dialog">
            <section className="add-on__media">
              <MediaViewer media={media} />
            </section>
            <section className="add-on__base-info">
              <div className="add-on-base-info">
                {description && (
                  <div
                    className="add-on-base-info-description text-format"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
                <ul className="add-on-datalist modal">
                  <li>
                    Add-On Supported SDK {sdkVersions?.map((version) => version.value).join(', ')}
                  </li>

                  <li>Latest release date {moment(releaseDate).format('YYYY.MM.DD')}</li>

                  <li>
                    <a className="add-on-datalist__link" href="/eula">
                      Standard license
                    </a>
                  </li>
                </ul>

                <div className="dialog__bottom-container">
                  {isPurchased && (
                    <span
                      className={clsx(
                        'purchased-notice add-on-base-info__purchased-notice add-on-base-info__purchased-notice--dialog',
                        { 'hidden-purchase-button': hidePurchaseButton }
                      )}
                    >
                      Purchased
                    </span>
                  )}

                  {isObtained && (
                    <span
                      className={clsx(
                        'purchased-notice  add-on-base-info__purchased-notice add-on-base-info__purchased-notice--dialog',
                        { 'hidden-purchase-button': hidePurchaseButton }
                      )}
                    >
                      Obtained
                    </span>
                  )}

                  {!isPurchased && !isObtained && !isUnavailable && (
                    <PriceDisplay
                      className="dialog__price"
                      price={price}
                      discountedPrice={discountedPrice}
                      isFree={isFree}
                    />
                  )}

                  <div className="dialog__actions">
                    <Link
                      className="dialog__button dialog__button--details"
                      to={`/add-on/${openAddonId}`}
                    >
                      View Full Details
                    </Link>

                    {!hidePurchaseButton &&
                      (isPurchased || isObtained ? (
                        <div>
                          {singlePackage ? (
                            <DownloadPackage
                              packageId={singlePackage.id}
                              className="add-on-base-info__download-btn download-button--main"
                              source="storefront"
                            >
                              Download Package
                            </DownloadPackage>
                          ) : (
                            <UnigineButton
                              onClick={() => setPackageListOpen(true)}
                              className="add-on-base-info__download-btn download-button--main"
                            >
                              Download Package
                            </UnigineButton>
                          )}
                        </div>
                      ) : (
                        <PurchaseButton
                          className="add-on-base-info__buy-btn"
                          isFree={isFree}
                          isWaiting={isProcessing}
                          ownedStatus={isInCart ? 'in cart' : ownedStatus}
                          onClick={handleBuy}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <IconButton aria-label="close" className="dialog__close-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
      <PackageListDialog
        versions={publishedVersions}
        canDownload={isPurchased || isObtained}
        open={packageListOpen}
        onClose={() => setPackageListOpen(false)}
      />
    </>
  );
};

export default AddonDialog;

import './RichTextEditor.scss';

import { lazy, FC, Suspense, forwardRef } from 'react';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import UnigineTooltip from 'app/components/Tooltip/Tooltip';

const RichTextEditorInternal = lazy(
  () => import('app/components/RichTextEditor/RichTextEditorInternal')
);

interface RichTextEditorProps {
  name?: string;
  initialValue?: string;
  disabled?: boolean;
  error?: { message?: string };
  // handler interface expected by react-hook-form
  onChange?: (evt: { target: { name?: string; value: string } }) => void;
  onBlur?: (evt: { target: { name?: string; value: string } }) => void;
  autosaveKey?: string;
  className?: string;
  tooltip?: string;
}

const getFullKey = (key?: string): string | undefined => key && `rte_save:${key}`;

const clearSave = (key: string): void => {
  const fullKey = getFullKey(key);
  if (fullKey) {
    // the editor sets an autosave microtask when unmounted (closed),
    // so we use a timeout to clear the save after the autosave fired
    setTimeout(() => {
      localStorage.removeItem(fullKey);
    }, 0);
  }
};

const RichTextEditor: FC<RichTextEditorProps> = forwardRef<HTMLDivElement, RichTextEditorProps>(
  (
    { name, initialValue, disabled, error, onChange, onBlur, autosaveKey, className, tooltip },
    ref
  ) => {
    return (
      <UnigineTooltip title={tooltip}>
        <div className={clsx('rich-text-editor', className, { 'rich-text-editor--error': error })}>
          <Suspense fallback={<Skeleton variant="rectangular" height={86} />}>
            <RichTextEditorInternal
              initialValue={initialValue}
              disabled={disabled}
              // if a handler is not defined, don't evaluate getData()
              onChange={(editor) => {
                if (!onChange) {
                  return undefined;
                }
                return onChange({ target: { name, value: editor.getData() } });
              }}
              onBlur={(editor) => {
                if (!onBlur) {
                  return undefined;
                }
                return onBlur({ target: { name, value: editor.getData() } });
              }}
              autosaveKey={getFullKey(autosaveKey)}
            />
          </Suspense>
          {error && <span className="rich-text-editor__helper-text">{error.message}</span>}
        </div>
      </UnigineTooltip>
    );
  }
);

export default RichTextEditor;

export { clearSave };

import './Publisher.scss';

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import defaultAvatar from 'app/assets/avatar-default.svg';

import ContactBox from 'app/components/ContactBox/ContactBox';
import StorefrontHeader from 'app/components/StorefrontHeader/StorefrontHeader';
import PublisherRelatedAddons from 'app/components/PublisherRelatedAddons/PublisherRelatedAddons';
import MediaViewer from 'app/main/sections/Addon/AddonMediaViewer';
import AddonDialog from 'app/main/sections/Addon/AddonDialog';

import useAppSelector from 'app/hooks/useAppSelector';
import useAppDispatch from 'app/hooks/useAppDispatch';
import useLoggedInUser from 'app/hooks/useLoggedInUser';

import {
  getPublisherAddonVersionsData,
  getPublisherData,
  publisherAddonsSelector,
  publisherInfoSelector,
  errorInfoSelector,
  errorPublisherAddonsInfoSelector,
} from 'app/main/sections/Publisher/store/publisherSlice';
import { contactPublisher } from 'app/main/sections/Publisher/store/contactPublisherSlice';

import ContactPublisherDialog from 'app/components/ContactPublisherDialog/ContactPublisherDialog';
import { IContactFormPublisher } from 'app/interfaces/publisher/IPublisher';

const Publisher: FC = () => {
  const dispatch = useAppDispatch();
  const { publisherId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isMailSended, setIsMailSended] = useState(false);

  const publisher = useAppSelector(publisherInfoSelector);
  const errorPublisher = useAppSelector(errorInfoSelector);
  const { isLoggedIn, userData } = useLoggedInUser();

  const publisherAddonsData = useAppSelector(publisherAddonsSelector);
  const errorPublisherAddons = useAppSelector(errorPublisherAddonsInfoSelector);

  const isPublisherMedia = publisher?.media && publisher.media.length > 0;

  useEffect(() => {
    if (publisherId) {
      Promise.all([
        dispatch(getPublisherData({ publisherId })),
        dispatch(getPublisherAddonVersionsData({ publisherId })),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, publisherId]);

  const onContactFormSubmit = async (values: IContactFormPublisher): Promise<void> => {
    setIsMailSended(true);
    if (publisherId) {
      const contactFormData = {
        ...values,
        publisherId,
      };
      await dispatch(contactPublisher({ contactFormData }));
      setTimeout(() => {
        setIsContactFormOpen(false);
        setIsMailSended(false);
      }, 1000);
    }
  };

  if (isLoading) {
    return (
      <>
        <StorefrontHeader />
        <div className="publisher__loading">
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <>
      <StorefrontHeader />
      <div className="main publisher">
        <div className="content-container content-container--main content-container--publisher">
          {!errorPublisher && publisher ? (
            <div className="publisher__main-content">
              <section className="publisher__description-wrapper">
                <div className="publisher__header">
                  <img
                    src={
                      publisher.icon80File
                        ? `${process.env.REACT_APP_STORE_API_URL}/media-files/${publisher?.icon80File.id}`
                        : defaultAvatar
                    }
                    alt={publisher.name}
                    width="80"
                    height="80"
                  />
                  <div className="publisher__header-info">
                    <h1 className="publisher__header-title">{publisher.name}</h1>
                    <p className="publisher__header-add-ons-count">
                      {publisher.publishedAddonsCount} Add-Ons Published
                    </p>
                  </div>
                </div>
                <div
                  className="publisher__description"
                  dangerouslySetInnerHTML={{ __html: publisher?.description }}
                />
                <ContactBox
                  contacts={publisher?.contacts}
                  hasEmail={publisher?.hasEmail}
                  isLoggedIn={isLoggedIn}
                  onOpen={() => setIsContactFormOpen(true)}
                />
              </section>
              <section className="publisher__media-wrapper">
                {isPublisherMedia ? (
                  <MediaViewer media={publisher?.media} />
                ) : (
                  <div className="publisher__media-wrapper--slider">
                    <PublisherRelatedAddons
                      publisherAddonsData={publisherAddonsData}
                      errorPublisherAddons={errorPublisherAddons}
                      sliderSize="small"
                    />
                  </div>
                )}
              </section>
            </div>
          ) : (
            <div>Error while loading publisher data</div>
          )}

          {isPublisherMedia && (
            <PublisherRelatedAddons
              publisherAddonsData={publisherAddonsData}
              errorPublisherAddons={errorPublisherAddons}
              sliderSize="big"
            />
          )}
        </div>
      </div>
      <AddonDialog />
      {publisherId && (
        <ContactPublisherDialog
          isOpen={isContactFormOpen}
          onClose={() => setIsContactFormOpen(false)}
          defaultUserName={userData?.firstname || ''}
          defaultUserEmail={userData?.email || ''}
          onContactFormSubmit={onContactFormSubmit}
          isMailSended={isMailSended}
        />
      )}
    </>
  );
};

export default Publisher;
